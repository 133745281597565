var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-overlay',{attrs:{"show":_vm.isShowLoading}},[_c('b-card',{attrs:{"title":_vm.$t('master.workType.form')}},[_c('validation-observer',{ref:"validateForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveData($event)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('master.workType.code') + _vm.showAsterisk}},[_c('validation-provider',{attrs:{"name":_vm.$t('master.workType.code'),"rules":"required|min:2|max:2|regex:^([A-Za-z]+)$"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"work-type-code",attrs:{"state":errors.length > 0 ? false : null,"plaintext":!!_vm.id},model:{value:(_vm.workTypeCode),callback:function ($$v) {_vm.workTypeCode=$$v},expression:"workTypeCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('master.workType.name')}},[_c('validation-provider',{attrs:{"name":_vm.$t('master.workType.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.workTypeName),callback:function ($$v) {_vm.workTypeName=$$v},expression:"workTypeName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('master.workType.danger')}},[_c('validation-provider',{attrs:{"name":_vm.$t('master.workType.danger'),"rules":"required|between:1,10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.workTypeDanger),callback:function ($$v) {_vm.workTypeDanger=$$v},expression:"workTypeDanger"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(errors.length === 0)?_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t('master.workType.dangerHint')))]):_vm._e()]}}],null,true)})],1)],1)],1),_c('b-row',{staticClass:"my-2",attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('h4',[_vm._v(_vm._s(_vm.$t('master.workType.jobDescription')))])]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"12","md":"6"}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.addJobRow}},[_vm._v(_vm._s(_vm.$t('master.workType.btnJobDescription')))])],1)],1),_vm._l((_vm.jobDescriptionLists),function(row,key){return _c('b-row',{key:'jobRow' + key,staticClass:"multi-form-row",attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"cols":"4","order":"1","md":"2","lg":"1"}},[_c('b-form-group',{attrs:{"label":_vm.$t('master.workType.num')}},[_c('b-form-input',{attrs:{"readonly":""},model:{value:(row.jobDescriptionNum),callback:function ($$v) {_vm.$set(row, "jobDescriptionNum", $$v)},expression:"row.jobDescriptionNum"}})],1)],1),_c('b-col',{attrs:{"cols":"12","order":"3","md":"12","lg":"10","order-lg":"2"}},[_c('b-form-group',{attrs:{"label":_vm.$t('master.workType.jobDescription') + '*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('master.workType.jobDescription'),"rules":"required","vid":'jobDescriptionName' + key},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(row.jobDescriptionName),callback:function ($$v) {_vm.$set(row, "jobDescriptionName", $$v)},expression:"row.jobDescriptionName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"text-right",attrs:{"cols":"8","order":"2","md":"10","lg":"1","order-lg":"4"}},[(_vm.jobDescriptionLists.length > 1)?_c('b-button',{attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.deleteJobRow(key)}}},[_vm._v(" "+_vm._s(_vm.$t('general.btnDelete'))+" ")]):_vm._e()],1)],1)}),_c('b-row',{staticClass:"my-2",attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('h4',[_vm._v(_vm._s(_vm.$t('master.workType.safety')))])]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"12","md":"6"}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.addSafetyRow}},[_vm._v(_vm._s(_vm.$t('master.workType.btnSafety')))])],1)],1),_vm._l((_vm.safetyCheckLists),function(row,key){return _c('b-row',{key:'safetyRow' + key,staticClass:"multi-form-row",attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"cols":"4","order":"1","md":"2","lg":"1"}},[_c('b-form-group',{attrs:{"label":_vm.$t('master.workType.num')}},[_c('b-form-input',{attrs:{"readonly":""},model:{value:(row.safetyNum),callback:function ($$v) {_vm.$set(row, "safetyNum", $$v)},expression:"row.safetyNum"}})],1)],1),_c('b-col',{attrs:{"cols":"12","order":"3","md":"12","lg":"10","order-lg":"2"}},[_c('b-form-group',{attrs:{"label":_vm.$t('master.workType.safety') + '*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('master.workType.safety'),"rules":"required","vid":'safetyName' + key},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(row.safetyName),callback:function ($$v) {_vm.$set(row, "safetyName", $$v)},expression:"row.safetyName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"text-right",attrs:{"cols":"8","order":"2","md":"10","lg":"1","order-lg":"4"}},[(_vm.safetyCheckLists.length > 1)?_c('b-button',{attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.deleteSafetyRow(key)}}},[_vm._v(" "+_vm._s(_vm.$t('general.btnDelete'))+" ")]):_vm._e()],1)],1)}),_c('b-row',[_c('b-col',{staticClass:"text-center"},[_c('b-button',{staticClass:"my-1",attrs:{"type":"submit","variant":"success","disabled":_vm.isBtnDisabled}},[(_vm.isBtnDisabled)?_c('b-spinner',{attrs:{"small":""}}):_c('feather-icon',{attrs:{"icon":"SaveIcon"}}),_vm._v(" "+_vm._s(_vm.$t('general.btnSubmit'))+" ")],1)],1)],1)],2)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }