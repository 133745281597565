<template>
  <div>
    <b-overlay :show="isShowLoading">
      <b-card :title="$t('master.workType.form')">

        <validation-observer ref="validateForm" #default="{invalid}">
          <b-form @submit.prevent="saveData">
            <b-row>
              <b-col md="6">
                <b-form-group :label="$t('master.workType.code') + showAsterisk">
                  <validation-provider #default="{ errors }" :name="$t('master.workType.code')" rules="required|min:2|max:2|regex:^([A-Za-z]+)$">
                    <b-form-input v-model="workTypeCode" :state="errors.length > 0 ? false : null" :plaintext="!!id" class="work-type-code"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('master.workType.name')">
                  <validation-provider #default="{ errors }" :name="$t('master.workType.name')" rules="required">
                    <b-form-input v-model="workTypeName" :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('master.workType.danger')">
                  <validation-provider #default="{ errors }" :name="$t('master.workType.danger')" rules="required|between:1,10">
                    <b-form-input v-model="workTypeDanger" :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small class="text-muted" v-if="errors.length === 0">{{ $t('master.workType.dangerHint')}}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Job step description -->

            <b-row align-v="center" class="my-2">
              <b-col cols="12" md="6">
                <h4>{{ $t('master.workType.jobDescription') }}</h4>
              </b-col>

              <b-col cols="12" md="6" class="text-right">
                <b-button variant="primary" @click="addJobRow">{{ $t('master.workType.btnJobDescription') }}</b-button>
              </b-col>
            </b-row>

            <b-row v-for="(row, key) in jobDescriptionLists" :key="'jobRow' + key" align-v="center" class="multi-form-row">
              <b-col cols="4" order="1" md="2" lg="1">
                <b-form-group :label="$t('master.workType.num')">
                    <b-form-input v-model="row.jobDescriptionNum" readonly=""/>
                </b-form-group>
              </b-col>

              <b-col cols="12" order="3" md="12" lg="10" order-lg="2">
                <b-form-group :label="$t('master.workType.jobDescription') + '*'">
                  <validation-provider #default="{ errors }" :name="$t('master.workType.jobDescription')" rules="required" :vid="'jobDescriptionName' + key">
                    <b-form-input v-model="row.jobDescriptionName" :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="8" order="2" md="10" lg="1" order-lg="4" class="text-right">
                <b-button variant="danger" size="sm" @click="deleteJobRow(key)" v-if="jobDescriptionLists.length > 1">
                  {{ $t('general.btnDelete') }}
                </b-button>
              </b-col>
            </b-row>

            <!-- Safety cross check -->

            <b-row align-v="center" class="my-2">
              <b-col cols="12" md="6">
                <h4>{{ $t('master.workType.safety') }}</h4>
              </b-col>

              <b-col cols="12" md="6" class="text-right">
                <b-button variant="primary" @click="addSafetyRow">{{ $t('master.workType.btnSafety') }}</b-button>
              </b-col>
            </b-row>

            <b-row v-for="(row, key) in safetyCheckLists" :key="'safetyRow' + key" align-v="center" class="multi-form-row">
              <b-col cols="4" order="1" md="2" lg="1">
                <b-form-group :label="$t('master.workType.num')">
                    <b-form-input v-model="row.safetyNum" readonly=""/>
                </b-form-group>
              </b-col>

              <b-col cols="12" order="3" md="12" lg="10" order-lg="2">
                <b-form-group :label="$t('master.workType.safety') + '*'">
                  <validation-provider #default="{ errors }" :name="$t('master.workType.safety')" rules="required" :vid="'safetyName' + key">
                    <b-form-input v-model="row.safetyName" :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="8" order="2" md="10" lg="1" order-lg="4" class="text-right">
                <b-button variant="danger" size="sm" @click="deleteSafetyRow(key)" v-if="safetyCheckLists.length > 1">
                  {{ $t('general.btnDelete') }}
                </b-button>
              </b-col>
            </b-row>

            <b-row>
              <b-col class="text-center">
                <b-button type="submit" variant="success" class="my-1" :disabled="isBtnDisabled">
                  <b-spinner small v-if="isBtnDisabled"/>
                  <feather-icon icon="SaveIcon" v-else/>
                  {{ $t('general.btnSubmit') }}
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>

      </b-card>
    </b-overlay>
  </div>

</template>

<script>
import {BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BSpinner, BForm, BButton, BOverlay} from 'bootstrap-vue'
import {required} from '@validations'
import formMixins from "@/mixins/formMixins"
import {WorkTypeService} from "@/services"

const workTypeService = new WorkTypeService()

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BSpinner,
    BOverlay,
  },
  mixins: [formMixins],
  async created() {
    this.id = this.$route.params.id

    if(this.id) {
      await this.getData()
    } else {
      this.addJobRow()
      this.addSafetyRow()
    }

    this.isShowLoading = false
    this.enabledCheckBackButton()
  },
  data: () => ({
    isShowLoading: true,
    isBtnDisabled: false,
    id: '',
    workTypeCode: '',
    workTypeName: '',
    workTypeDanger: '',
    jobDescriptionLists: [],
    safetyCheckLists: []
  }),
  computed: {
    showAsterisk() {
      return this.id ? '' : '*'
    },
  },
  methods: {
    async getData() {
      let queryResult = await workTypeService.getData(this.id)
      if(queryResult.isSuccess) {
        let {data} = queryResult
        let {result} = data
        this.workTypeCode = result.workTypeCode
        this.workTypeDanger = result.workTypeDanger
        this.workTypeName = result.workTypeName
        this.jobDescriptionLists = result.jobDescriptionLists
        this.safetyCheckLists = result.safetyCheckLists

        this.jobDescriptionLists = this.jobDescriptionLists.map((data, key) => ({...data, jobDescriptionNum: key + 1}))
        this.safetyCheckLists = this.safetyCheckLists.map((data, key) => ({...data, safetyNum: key + 1}))
      } else {
        this.$store.commit('main/setToastError', queryResult.data)
        await this.$router.push({name: 'pageMasterWorkType'})
      }
    },
    saveData() {
      this.$refs.validateForm.validate().then(async success => {
        if (success) {
          this.isBtnDisabled = true
          let actionData = {
            workTypeCode: this.workTypeCode,
            workTypeDanger: this.workTypeDanger,
            workTypeName: this.workTypeName,
            jobDescriptionLists: this.jobDescriptionLists,
            safetyCheckLists: this.safetyCheckLists,
          }
          let saveResult

          if (this.id) {
            saveResult = await workTypeService.updateData(this.id, actionData)
          } else {
            saveResult = await workTypeService.createData(actionData)
          }

          this.isBtnDisabled = false

          if (saveResult.isSuccess) {
            this.$store.commit('main/setToastSuccess', {title: 'Save success', text: ''})
            await this.$router.push({name: 'pageMasterWorkType'})
          } else {
            this.$store.commit('main/setToastError', saveResult.data)
          }
        }
      })
    },
    addJobRow() {
      this.jobDescriptionLists.push({
        jobDescriptionId: '',
        jobDescriptionName: '',
        jobDescriptionNum: this.jobDescriptionLists.length + 1
      })
    },
    deleteJobRow(row) {
      this.jobDescriptionLists.splice(row, 1)
      this.jobDescriptionLists = this.jobDescriptionLists.map((data, key) => ({...data, jobDescriptionNum: key + 1}))
    },
    addSafetyRow() {
      this.safetyCheckLists.push({
        safetyId: '',
        safetyName: '',
        safetyNum: this.safetyCheckLists.length + 1
      })
    },
    deleteSafetyRow(row) {
      this.safetyCheckLists.splice(row, 1)
      this.safetyCheckLists = this.safetyCheckLists.map((data, key) => ({...data, safetyNum: key + 1}))
    }
  }
}
</script>

<style lang="scss">
.multi-form-row {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 25px;
  padding-bottom: 10px;
}

.work-type-code {
  text-transform: uppercase;
}
</style>
